const OrigemInventarioRiscoExameEnum = Object.freeze({
    AVALIACAO_CLINICA_OCUPACIONAL: 'AVALIACAO_CLINICA_OCUPACIONAL',
    PERIGO: 'PERIGO',
    ADICIONADO_MANUALMENTE: 'ADICIONADO_MANUALMENTE'
})

export const OrigemInventarioRiscoExameLabelEnum = new Map([
    [OrigemInventarioRiscoExameEnum.AVALIACAO_CLINICA_OCUPACIONAL, 'Avaliação clínica ocupacional'],
    [OrigemInventarioRiscoExameEnum.PERIGO, 'Perigo'],
    [OrigemInventarioRiscoExameEnum.ADICIONADO_MANUALMENTE, 'Adicionado manualmente']
])

export default OrigemInventarioRiscoExameEnum;
