const GrupoRiscos = Object.freeze({
    FISICO: 1,
    QUIMICO: 2,
    BIOLOGICO: 3,
    ERGONOMICO: 4,
    ACIDENTE_MECANICO: 5,
    OUTRO: 6
})

export const GrupoRiscosLabelEnum = new Map([
    [GrupoRiscos.FISICO, 'Físico'],
    [GrupoRiscos.BIOLOGICO, 'Biológico'],
    [GrupoRiscos.QUIMICO, 'Químico'],
    [GrupoRiscos.ERGONOMICO, 'Ergonômico'],
    [GrupoRiscos.ACIDENTE_MECANICO, 'Acidente Mecânico'],
    [GrupoRiscos.OUTRO, 'Outro']
]);

export default GrupoRiscos;
