<template>
    <Dialog 
        v-model:visible="isOpen"
        header="Perigos vinculados" 
        :style="{ width: '50vw' }" 
        :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        closeOnEscape
        @after-hide="resetProps"
    >
        <AppLoadingWrapper v-if="loading"/>
        <DataTable
            v-if="!loading"
            dataKey="id"
            :value="records"
            responsiveLayout="scroll"
            breakpoint="640px"
        >
            <template #empty>
                <div class="flex flex-column justify-content-center align-items-center p-2">
                    <span style="font-size: 2rem; color: var(--gray-500);">Nenhum dado encontrado.</span>
                </div>
            </template>

            <Column field="risco.nome" header="Perigo/Fator de risco" />
            <Column field="risco.grupoRisco" header="Grupo de risco">
                <template #body="{ data }">
                    {{ enumGrupoRiscosLabel.get(data?.risco?.grupoRisco) }}
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script>
import NestedService from '@/services/NestedService';
import { GrupoRiscosLabelEnum } from '../../../../enums/GrupoRiscos';
import { showError } from '../../../../utils/Toast';
import AppLoadingWrapper from '../../../../components/AppLoadingWrapper.vue';

export default {
    components: {
        AppLoadingWrapper
    },
    created() {
        this.service = new NestedService('risco-procedimento');
    },
    data() {
        return {
            service: null,
            enumGrupoRiscosLabel: GrupoRiscosLabelEnum,
            isOpen: false,
            loading: false,
            records: [],
        }
    },
    methods: {
        async open(procedimentoId) {
            this.isOpen = true;
            await this.load(procedimentoId);
        },
        async load(procedimentoId) {
            try {
                this.loading = true;
                const { data } = await this.service.findByOrigem({ filter: { procedimentoId } }, 'risco-procedimento');
                this.records = data?.items;
                this.loading = false;
            } catch (error) { 
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        resetProps() {
            this.records = [];
        }
    }
}
</script>

<style>

</style>