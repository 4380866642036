const TipoPeriodicidadeEnum = Object.freeze({
    NONE: null,
    PADRAO: 'padrao',
    IDADE: 'idade',
    MEDICAO: 'medicao',
})

export const TipoPeriodicidadeLabelEnum = new Map([
    [TipoPeriodicidadeEnum.NONE, 'Nenhuma'],
    [TipoPeriodicidadeEnum.PADRAO, 'Padrão'],
    [TipoPeriodicidadeEnum.IDADE, 'Idade'],
    [TipoPeriodicidadeEnum.MEDICAO, 'Medição']
])

export default TipoPeriodicidadeEnum;
