<template>
    <GridExamesVue />
</template>

<script>
import GridExamesVue from '../inventario-risco-exame/GridExames.vue'

export default {
    components: {
        GridExamesVue
    }
}
</script>

<style>

</style>