<template>
    <Card>
        <template #content>
            <AppPaginatedGridNested
                ref="gridExames"
                :service="service"
                :parentName="'inventario-risco'"
                :queryParams="{ inventarioRiscoId: this.$route.params.id }"
                :defaultProps="{ inventarioRiscoId: this.$route.params.id }"
                :expander="false"
                :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
                :isPaginated="false"
                :showButtonAlterar="true"
                :isValid="isValid"
                @onValidate="onValidate"
                @onAfterSave="onAfterSave"
            >
                <template #header>
                    <h4>Exames</h4>
                </template>

                <template #columns>
                    <Column field="procedimento" header="Exame">
                        <template #body="{ data }">
                            {{ data?.procedimento?.name }}
                            <i
                                v-if="data?.origemProcedimento === enumOrigemInventarioRiscoExame.AVALIACAO_CLINICA_OCUPACIONAL"
                                v-tooltip.bottom="'Avaliação clínica ocupacional obrigatória'"
                                class="pi pi-info-circle mr-3"
                                style="color: red"
                            ></i>
                        </template>
                        <template #editor="{ data, field }">
                            <DropdownExame 
                                :idValue="data.procedimentoId" 
                                v-model="data[field]" 
                                @update:modelValue="data.procedimentoId = data[field]?.id" 
                            />
                        </template>
                    </Column>
                    <Column field="origemProcedimento" header="Origem">
                        <template #body="{ data }">
                            <Button
                                v-if="data?.origemProcedimento === enumOrigemInventarioRiscoExame.PERIGO"
                                :label="enumOrigemInventarioRiscoLabelExame.get(data?.origemProcedimento)"
                                @click="openDialogDetalhesRiscoProcedimento(data?.procedimento?.id)"
                                class="p-button-sm"
                            />

                            <span v-else>
                                {{ enumOrigemInventarioRiscoLabelExame.get(data?.origemProcedimento) }}
                            </span>
                        </template>
                    </Column>
                    <Column header="Tipo periodicidade">
                        <template #body="{ data }">
                            {{ enumTipoPeriodicidadeLabel.get(data?.inventarioRiscoExamePeriodicidade?.tipo) }}
                        </template>
                    </Column>
                    <Column header="Periodicidade">
                        <template #body="{ data }">
                            <Button
                                v-if="data?.inventarioRiscoExamePeriodicidade?.tipo !== enumTipoPeriodicidade.PADRAO"
                                label="Visualizar"
                                @click="openDialogDetalhesPeriodicidade(data)"
                                class="p-button-sm"
                            />
                            <span v-else>
                                {{ data?.inventarioRiscoExamePeriodicidade?.periodicidadePadrao }}
                            </span>
                        </template>
                    </Column>
                    <Column field="tipoAvaliacao" header="Tipo avaliação">
                        <template #body="{ data }">{{ enumTipoAvalicacaoInventarioRiscoExameLabel.get(data?.tipoAvaliacao) }}</template>
                        <template #editor="{ data, field }">
                            <Dropdown 
                                class="w-full"
                                v-model="data[field]" 
                                :options="optionsTipoAvaliacao" 
                                optionLabel="label" 
                                optionValue="value" 
                            />
                        </template>
                    </Column>
                    <Column header="Exigido em" />
                </template>
            </AppPaginatedGridNested>

            <DialogDetalhesRiscoProcedimento ref="dialogDetalhesRiscoProcedimento" />
            <DialogDetalhePeriodicidade ref="dialogDetalhesPeriodicidade" />
        </template>
    </Card>
</template>

<script>
import AppPaginatedGridNested from '../../../../components/grid/AppPaginatedGridNested.vue';
import NestedService from '../../../../services/NestedService';
import OrigemInventarioRiscoExameEnum, { OrigemInventarioRiscoExameLabelEnum } from '../../../../enums/OrigemInventarioRiscoExameEnum';
import TipoPeriodicidadeEnum, { TipoPeriodicidadeLabelEnum } from '../../../../enums/TipoPeriodicidadeEnum';
import TipoAvalicacaoInventarioRiscoExameEnum, {
    TipoAvalicacaoInventarioRiscoExameLabelEnum
} from '../../../../enums/TipoAvalicacaoInventarioRiscoExameEnum';
import DialogDetalhesRiscoProcedimento from './DialogDetalhesRiscoProcedimento.vue';
import DialogDetalhePeriodicidade from './DialogDetalhePeriodicidade.vue';
import DropdownExame from '../../../exames/components/DropdownExame.vue';
import { showError } from '../../../../utils/Toast';

export default {
    components: {
        AppPaginatedGridNested,
        DialogDetalhesRiscoProcedimento,
        DialogDetalhePeriodicidade,
        DropdownExame
    },
    created() {
        this.service = new NestedService('inventario-risco-exame');
    },
    data() {
        return {
            service: null,
            serviceRiscoProcedimento: null,
            isValid: true,
            enumOrigemInventarioRiscoExame: OrigemInventarioRiscoExameEnum,
            enumOrigemInventarioRiscoLabelExame: OrigemInventarioRiscoExameLabelEnum,
            enumTipoPeriodicidade: TipoPeriodicidadeEnum,
            enumTipoPeriodicidadeLabel: TipoPeriodicidadeLabelEnum,
            enumTipoAvalicacaoInventarioRiscoExameLabel: TipoAvalicacaoInventarioRiscoExameLabelEnum,
            optionsTipoAvaliacao: [
                {
                    label: TipoAvalicacaoInventarioRiscoExameLabelEnum.get(TipoAvalicacaoInventarioRiscoExameEnum.EXAME_DE_MONITORAMENTO),
                    value: TipoAvalicacaoInventarioRiscoExameEnum.EXAME_DE_MONITORAMENTO
                },
                {
                    label: TipoAvalicacaoInventarioRiscoExameLabelEnum.get(TipoAvalicacaoInventarioRiscoExameEnum.EXAME_DE_APTIDAO),
                    value: TipoAvalicacaoInventarioRiscoExameEnum.EXAME_DE_APTIDAO
                }
            ],
            labelBotaoAdicionarItem: 'Adicionar exame'
        };
    },
    methods: {
        onValidate(data) {
            if (!data?.procedimentoId) {
                showError(this.$toast, {}, 'Exame obrigatório.');
                this.isValid = false;
                return;
            }

            this.isValid = true;
        },
        onAfterSave() {
            this.$refs.gridExames.load();
        },
        async openDialogDetalhesRiscoProcedimento(procedimentoId) {
            this.$refs.dialogDetalhesRiscoProcedimento.open(procedimentoId);
        },
        async openDialogDetalhesPeriodicidade(data) {
            this.$refs.dialogDetalhesPeriodicidade.open(data.id, data?.inventarioRiscoExamePeriodicidade?.tipo);
        }
    }
};
</script>