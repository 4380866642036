const TipoAvalicacaoInventarioRiscoExameEnum = Object.freeze({
    EXAME_DE_MONITORAMENTO: 1,
    EXAME_DE_APTIDAO: 2
});

export const TipoAvalicacaoInventarioRiscoExameLabelEnum = new Map([
    [TipoAvalicacaoInventarioRiscoExameEnum.EXAME_DE_MONITORAMENTO, 'Exame de monitoramento'],
    [TipoAvalicacaoInventarioRiscoExameEnum.EXAME_DE_APTIDAO, 'Exame de aptidão']
]);
  
export default TipoAvalicacaoInventarioRiscoExameEnum;
