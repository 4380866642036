<template>
    <Dialog
        v-model:visible="isOpen"
        header="Detalhes da periodicidade"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        closeOnEscape
    >
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading && record?.tipo === enumTipoPeriodicidade.IDADE">
            {{ record?.periodicidadeFaixaIdade1 }}
        </div>
    </Dialog>
</template>

<script>
import NestedService from '@/services/NestedService';
import AppLoadingWrapper from '../../../../components/AppLoadingWrapper.vue';
import TipoPeriodicidadeEnum from '../../../../enums/TipoPeriodicidadeEnum';
import { showError } from '../../../../utils/Toast';

export default {
    components: {
        AppLoadingWrapper
    },
    created() {
        this.service = new NestedService('inventario-risco-exame-periodicidade');
    },
    data() {
        return {
            service: null,
            isOpen: false,
            loading: false,
            record: {},
            filtrosExtras: {},
            enumTipoPeriodicidade: TipoPeriodicidadeEnum,
        };
    },
    methods: {
        async open(inventarioRiscoExameId, tipoPeriodicidade) {
            this.isOpen = true;
            this.filtrosExtras = {
                where: [
                    {
                        prop: 'inventario_risco_exame_periodicidade.tipo',
                        operator: 'equal',
                        values: [tipoPeriodicidade]
                    }
                ]
            };
            await this.load(inventarioRiscoExameId);
        },
        async load(inventarioRiscoExameId) {
            try {
                this.loading = true;
                
                const { data } = await this.service.findByParent('inventario-risco-exame', inventarioRiscoExameId, {
                    filtrosExtras: this.filtrosExtras
                });

                this.record = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
            
        }
    }
};
</script>